<template>
  <section class="benner">
    <h1>{{ $t('Head.Lecturer') }}</h1>
    <!-- 讲师专区 -->
    <p>{{ $t('teacher.banner_tip') }}</p>
    <!-- 企业内训讲师资源，可以快速了解企业师资力量 -->
  </section>

  <section class="filter-wrap" id="pageTop">
    <div class="content">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="statusArr"
        @onChange="statusChange"
      >
        <template v-slot:right>
          <div class="search">
            <div class="search-icon" @click="search">
              <SearchOutlined style="color: #656565" />
            </div>
            <a-input
              class="search-input"
              v-model:value.trim="searchVal"
              :placeholder="$t('please_enter_keywords')"
              allow-clear
              @pressEnter="search"
            />
             <!-- 请输入关键字 -->
          </div>
        </template>
      </ListFilter>
    </div>
  </section>

  <section class="list">
    <div class="content">
      <a-spin :spinning="courseLoading">
        <template v-if="courseData.length">
          <div class="cards">
            <div class="item" v-for="item in courseData" :key="item.courseId">
              <div class="img">
                <img v-if="item.cover" :src="item.cover" alt="cover" />
                <img
                  v-else
                  src="../../assets/image/live-cover.png"
                  alt="cover"
                />
              </div>
              <div class="info">
                <div class="row">
                  <div class="title">{{ item.roomName }}</div>
                  <div class="status" v-if="currentTime > item.endTime">
                    {{ $t('CM_Expired') }}
                    <!-- 已过期 -->
                  </div>
                  <div class="status gray" v-else>{{ $t('CM_UnExpired') }}</div>
                  <!-- 未过期 -->
                </div>
                <div class="row">
                  <div class="addr"></div>
                  <div class="users">
                    <UserOutlined style="font-size: 14px" />
                    <p>{{ item.trainStudy ? item.trainStudy.length : 0 }} {{ $t('subject.people') }}</p>
                    <!-- 人 -->
                  </div>
                </div>
                <div class="row">
                  <div class="time">
                    <ClockCircleOutlined style="font-size: 15px" />
                    <p>
                      {{ dateFormat(item.startTime) }} ~
                      {{ dateFormat(item.endTime) }}
                    </p>
                  </div>
                  <template v-if="currentTime <= item.endTime">
                    <template v-if="item.liveType == 1">
                      <a
                        class="link"
                        :href="`polyvlive://start?channelId=${
                          item.roomId
                        }&passwd=${
                          item.roomPassword || item.channelPassword
                        }&pdn=api.polyv.net`"
                        >{{ $t('live.start_client') }}</a
                      >
                      <!-- 启动客户端 -->
                      <a
                        class="link"
                        :target="newWindow == 1 ? '_blank' : '_self'"
                        @click="webOpen(item)"
                      >
                      {{ $t('live.web_live') }}
                        <!-- 网页开播 -->
                      </a>
                    </template>
                    <a
                      v-if="item.liveType == 2"
                      class="link"
                      @click="wxOpen(item)"
                      >{{ $t('live.start_live') }}</a
                    >
                    <!-- 开启直播 -->
                    <a
                      v-if="item.liveType == 3"
                      class="link"
                      @click="ddOpen(item)"
                      >{{ $t('live.start_live') }}</a
                    >
                    <!-- 开启直播 -->
                  </template>
                  <template v-else>
                    <a
                      v-if="item.liveType == 2"
                      class="link"
                      @click="wxOpen(item, 'view')"
                      >{{ $t('live.view_live') }}</a
                    >
                    <!-- 查看直播 -->
                    <a
                      v-if="item.liveType == 3"
                      class="link"
                      @click="ddOpen(item)"
                      >{{ $t('live.view_live') }}</a
                    >
                    <!-- 查看直播 -->
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :defaultPageSize="20"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t('pre') }}</a-button>
                <a-button v-else-if="type === 'next'">{{ $t('next') }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import ListFilter from "@/components/filter/ListFilter.vue";
import { liveCourse, startLive } from "@/api/teacher";
import { getWxLiveStatus } from "@/api/live";
import wxwork from "@/utils/wxwork";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const currentTime = Date.parse(new Date()) / 1000;
    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    const isWxWork = /wxwork/i.test(ua);
    const isMac = /macintosh|mac os x/i.test(ua);
    // 状态
    const statusArr = [
      {
        name: $t('CM_Expired'),
        // name: "已过期",
        id: 1,
      },
      {
        name: $t('CM_UnExpired'),
        // name: "未过期",
        id: 2,
      },
    ];
    let statusActive = 0;
    const statusChange = (arr) => {
      statusActive = arr[0];
      getCourseList();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getCourseList();
      };

    // 课程
    const courseData = ref([]),
      courseLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getCourseList = (scroll = false) => {
        courseLoading.value = true;
        liveCourse({
          page: currentPage.value,
          pageSize: 10,
          keyword: searchVal.value,
          timeStatus: statusActive,
        }).then((res) => {
          courseLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            courseData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getCourseList(true);
      };
    getCourseList();

    const webOpen = (item) => {
      startLive(item.detailId).then((res) => {
        let token = res.data;
        let url = "https://live.polyv.net/web-start/?channelId=" + item.roomId;
        let urlStr = `https://live.polyv.net/teacher/auth-login?channelId=${
          item.roomId
        }&token=${token}&redirect=${encodeURI(url)}`;
        if (store.getters.companyInfo.useNewWindow == 1) {
          window.open(urlStr);
        } else {
          window.location.href = urlStr;
        }
      });
    };

    const wxOpen = (item, type) => {
      if (isWxWork) {
        if (isMac) {
          proxy.$message.info($t('live.ios_tip'));
          // 苹果电脑不支持开启直播，请用企微直播应用开启直播
        } else {
          getWxLiveStatus({
            taskId: item.taskId,
            detailId: item.detailId,
          }).then((res) => {
            if (res.ret === 0) {
            }
          });
          wxwork.startLiving(item.roomId);
        }
      } else {
        const msg =
          type == "view" ? $t('live.wxwork_tip2') : $t('live.wxwork_tip');
        proxy.$message.info(msg);
      }
    };

    const ddOpen = (item) => {
      proxy.$message.info($t('live.is_open_dingding_live'));
      // 正在打开钉钉直播
      window.open(
        `dingtalk://dingtalkclient/action/start_uniform_live?liveUuid=${item.roomId}`
      );
      // if (isDingding) {
      // } else {
      //   proxy.$message.info("请用钉钉开启直播");
      // }
    };

    onMounted(() => {
      if (isWxWork) {
        wxwork.agentConfig();
      }
    });

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      dateFormat,
      currentTime,
      statusArr,
      statusChange,
      searchVal,
      search,
      getCourseList,
      courseData,
      courseLoading,
      currentPage,
      pageTotal,
      pageChange,
      webOpen,
      wxOpen,
      ddOpen,
    };
  },
};
</script>

<style lang="less" scoped>
.benner {
  height: 324px;
  background-image: url("../../assets/image/teacher_calendar_banner.png");
  background-position: center;
  background-repeat: repeat-y;
  .mixinFlex(center; center; column);
  h1 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: 2px;
  }
  p {
    color: #fff;
    font-size: 26px;
    margin-bottom: 60px;
  }
}

.filter-wrap {
  background-color: #fff;
  margin-top: -60px;
  .content {
    .mixinWrap();
    .search {
      .mixinFlex(space-between; center);
      width: 220px;
      height: 32px;
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid #d9d9d9;
      float: right;
      &-icon {
        width: 34px;
        height: 14px;
        .mixinFlex(center; center);
        border-right: 1px solid #bbbbbb;
        cursor: pointer;
      }
      ::v-deep(.ant-input-affix-wrapper-focused) {
        border-color: none;
        box-shadow: none;
      }
      &-input {
        border: none;
        width: calc(100% - 34px);
        height: 100%;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        ::v-deep(.ant-input) {
          background-color: #fff;
        }
      }
    }
  }
}

.list {
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      padding-left: 54px;
      border-left: 1px solid #dddddd;
      position: relative;
      &::before {
        content: "";
        background-color: #dddddd;
        position: absolute;
        left: -1px;
        top: -22px;
        width: 1px;
        height: 22px;
      }
      &::after {
        content: "";
        background-color: @color-page-light;
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 1px;
        height: 87px;
      }
      .item {
        height: 201px;
        background-color: #fff;
        margin-bottom: 20px;
        position: relative;
        &::before {
          content: "";
          width: 29px;
          height: 29px;
          background-color: #dddddd;
          border-radius: 50%;
          position: absolute;
          left: -69px;
          top: calc(50% - 15px);
        }
        .mixinFlex(space-between; center);
        .img {
          .mixinImgWrap(310px; 201px);
        }
        .info {
          width: calc(100% - 310px);
          height: 201px;
          padding: 30px;
          .row {
            .mixinFlex(space-between; center);
            .title {
              width: calc(100% - 100px);
              .mixinEllipsis(34px);
              color: #333;
              font-size: 24px;
            }
            .status {
              font-size: 18px;
              font-weight: 700;
              color: #666;
              &.gray {
                color: #999;
              }
            }
            .time,
            .users,
            .addr {
              .mixinFlex(flex-start; center);
              color: #999;
              p {
                font-size: 18px;
                margin: 0 0 0 6px;
                .mixinEllipsis(30px);
              }
            }
            .addr {
              width: calc(100% - 100px);
            }
            .time {
              font-size: 20px;
              width: calc(100% - 230px);
            }
            .link {
              font-size: 18px;
              height: 33px;
              line-height: 31px;
              text-align: center;
              color: @color-theme;
              border: 1px solid @color-theme;
              border-radius: 4px;
              padding: 0 5px;
            }
            &:first-child {
              margin-bottom: 12px;
            }
            &:last-child {
              margin-top: 32px;
            }
          }
        }
      }
    }
  }
}
</style>
